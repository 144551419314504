<template>
    <div class="becomeTeacher">
        <table style="margin-left:65px;margin-top:39px;">
            <tr>
                <td style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">姓名：</td>
                <td style="text-align: left;">
                    <el-input style="width:340px;" v-model="user.name" placeholder="请输入真实姓名"></el-input>
                </td>
            </tr>
            <tr>
                <td style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">身份证号：</td>
                <td style="text-align: left;">
                    <el-input style="width:340px;" v-model="user.id_card" placeholder="请输入身份证号"></el-input>
                </td>
            </tr>
			<tr>
			    <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">主要职务：</td>
			    <td style="text-align: left;">
			        <el-input style="width:340px;" :rows="3" type="textarea" v-model="user.duties" placeholder="此内容用于外部宣传, 请填写少于20字"></el-input>
			    </td>
			</tr>
            <tr>
                <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">个人简介：</td>
                <td style="text-align: left;">
                    <el-input style="width:340px;" :rows="3" type="textarea" v-model="user.explain" placeholder="请填写内容小于500字"></el-input>
                </td>
            </tr>
            <tr>
                <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">社会职务：</td>
                <td style="text-align: left;">
                    <el-input style="width:340px;" type="textarea" :rows="3" v-model="user.society_job" placeholder="请填写内容小于500字"></el-input>
                </td>
            </tr>
            <tr style="height:160px;">
                <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">上传证书：</td>
                <td class="flex" style="margin-top:20px;">
                    <upload-img @getimg="zgzs" :impose="false">
                        <div class="upcss flex flexColumn spacebetween" style="color:#999999">
                            <div class="borders flex alignCenter spacearound">
                                <img v-if="user.senior" :src="$store.getters.Baseurl + user.senior" alt="" style="width:100%;">
                                <i v-else class="el-icon-plus" style="width:12px;height:12px;color:#dddddd;"></i>
                            </div>
                            资格证书
                        </div>
                    </upload-img>
                    <div style="width:20px;"></div>
                    <upload-img @getimg="zyzs" :impose="false">
                        <div class="upcss flex flexColumn spacebetween" style="color:#999999">
                            <div class="borders flex alignCenter spacearound">
                                <img v-if="user.qualification" :src="$store.getters.Baseurl + user.qualification" alt="" style="width:100%;">
                                <i v-else class="el-icon-plus" style="width:12px;height:12px;color:#dddddd;"></i>
                            </div>
                            职业证书
                        </div>
                    </upload-img>
                </td>
            </tr>
            <tr style="height:160px;">
                <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">荣誉证书：</td>
                <td class="flex flexWrap" style="width:600px;">
                    <div class="imgVessel" style="margin-right:20px;margin-bottom:10px;" v-show="user.honour.length > 0" v-for="(im,ix) in user.honour" :key="ix">
                        <img style="width:100%;height:100%;" :src="$store.getters.Baseurl + im" alt="">
                        <div class="deleteBtn flex alignCenter spacearound">
                            <i class="el-icon-delete cursorP" @click="delImg(ix)"></i>
                        </div>
                    </div>
                    <upload-img @getimg="ryzs" :impose="false">
                        <div class="upcss flex flexColumn spacebetween" style="color:#999999">
                            <div class="borders flex alignCenter spacearound">
                                <i class="el-icon-plus cursorP" style="width:12px;height:12px;color:#dddddd;"></i>
                            </div>
                        </div>
                    </upload-img>
                </td>
            </tr>
        </table>
        <div style="margin-left:90px;font-size:16px;color:#212223;width:800px;text-align:left;">拟讲课题：</div>
        <table style="margin-left:65px;" v-for="(im,ix) in course_info.length" :key="ix">
            <tr style="height:80px;">
                <td style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">标题：</td>
                <td>
                    <el-input style="width:570px;" v-model="course_info[ix].title" placeholder="请输入标题"></el-input>
                </td>
            </tr>
            <tr style="height:80px;">
                <td valign="top" style="width:100px;padding-right:10px;font-size:15px;color:#212223;text-align:right;">简介：</td>
                <td>
                    <el-input resize="none" v-model="course_info[ix].introduce" type="textarea" style="width:570px;height:192px;" rows="8" placeholder="请输入简介"></el-input>
                </td>
            </tr>
        </table>
        <div class="btns">
            <button class="add cursorP" @click="add">新增</button>
            <button class="remove cursorP" @click="remove">删除</button>
        </div>
        <div class="btns" style="margin-top:46px;padding-bottom:50px;">
            <button class="add cursorP" @click="submit">确认提交</button>
        </div>
    </div>
</template>
<script>
import { lecturer_apply } from '@/utils/Api/userList'
import uploadImg from '@/components/uploadimg'
export default {
    name:'becomeTeacher',
    components:{uploadImg},
    data(){
        return{
            user:{
                honour:[]
            },
            course_info:[],
        }
    },
    created(){
        this.course_info.push({
                title:'',
                introduce:''
            })
    },
    methods:{
        async submit(){
            let params = {
                ...this.user,
                honour: this.user.honour.join(','),
                course_info: JSON.stringify(this.course_info)
            }
            if(!params.name){
                this.$message.warning('请填写姓名')
                return
            }
            if(!params.id_card){
                this.$message.warning('请填写身份证号码')
                return
            }
            if(!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/).test(params.id_card)){
                this.$message.warning('身份证号码填写不正确')
                return
            }
            if(!params.explain){
                this.$message.warning('请填写个人简介')
                return
            }
            if(!params.duties){
                this.$message.warning('请填写主要职务')
                return
            }
            if(!params.society_job){
                this.$message.warning('请填写社会职务')
                return
            }
            if(!params.senior){
                this.$message.warning('上传资格证书')
                return
            }
            if(!params.qualification){
                this.$message.warning('上传职业证书')
                return
            }
            if(!params.honour){
                this.$message.warning('上传荣誉证书')
                return
            }
            let res = await lecturer_apply(params)
            if(res.length < 1){
                this.$message.success('申请讲师成功，请等待审核。')
            }
        },
        add(){
            this.course_info.push({
                title:'',
                introduce:''
            })
        },
        remove(ix){
            this.$confirm('确认删除此拟将课题吗？',{
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(()=>{
                this.course_info.splice(this.course_info.length-1, 1)
                this.$message.success('已删除')
            }).catch(()=>{})
        },
        zgzs(value){
            console.log(value)
            this.user.senior = value
            this.$forceUpdate()
        },
        zyzs(value){
            console.log(value)
            this.user.qualification = value
            this.$forceUpdate()
        },
        ryzs(value){
            this.user.honour.push(value)
        },
        delImg(ix){
            this.$confirm('是否删除此照片?', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.user.honour.splice(ix,1)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.btns{
    margin-left: 180px;
    height: 30px;
    text-align: left;
    .add, .remove{
        width: 79px;
        height: 33px;
        opacity: 1;
        border-radius: 2px;
        font-size: 15px;
        color: #fff;
    }
    .add{
        background: #47d7e3;
    }
    .remove{
        background: #F52F4E;
        margin-left: 15px;
    }
}
.imgVessel{
    position: relative;
    width: 100px;
    height: 100px;
    transition: all .3s;
    :hover.deleteBtn{
            opacity: 1;
        }
    .deleteBtn{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color:#fff;
        background: rgba(0,0,0,0.6);
        opacity: 0;
    }
}
.upcss{
    height: 130px;
    width: 100px;
    .borders{
        width: 100px;
        height: 100px;
        border: 1px solid #dddddd;
    }
}
.becomeTeacher{
    tr{
        height: 55px;
    }
}
</style>